import React from "react"
import styled from "styled-components"
import { determineIsMobile } from "../utils"

const Wrapper = styled.div`
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  z-index: -10;
`

const TextWrapper = styled.div`
  padding: ${props => props.isMobile ? '60px 20px' : '60px 0px'};
  width: ${props => props.isMobile ? '100%' : '50vw'};
  margin: ${props => props.isMobile ? '0px' : 'auto'};

`

const Title = styled.h2`
  font-size: 66px;
  color: white;
  font-weight: 500;
  font-family: Josefin Sans;
`

const SubTitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-family: Josefin Sans;
  margin-top: 10px;
  margin-bottom: 40px;
  font-size: 20px;
  white-space: pre-line;
  line-height: 26px;
`

const DiamondShape = styled.div`
  position: absolute;
  left: 50%;
  bottom: -16px;
  width: 50px;
  height: 50px;
  transform: translateX(-50%) rotate(45deg);
  background-color: ${(props) => props.backgroundColor};
`

const TitleHeader = ({ title, subTitle, backgroundColor }) => {

  const isMobile = determineIsMobile()


  return (
    <Wrapper backgroundColor={backgroundColor}>
      <TextWrapper isMobile={isMobile}>
        {title && <Title>{title}</Title>}
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
      </TextWrapper>
      <DiamondShape backgroundColor={backgroundColor} />
    </Wrapper>
  )
}

export default TitleHeader