const api = process.env.REACT_APP_BACKEND_URL;

export async function createItem(model, item) {
    const response = await fetch(`${api}/${model}`, {
        method: "POST",
        headers:{
            'Content-type':"application/json"
        },
        body: JSON.stringify(item)
    })
    const json = await response.json()
    return json
}

export async function updateItem(model, item) {
    const response = await fetch(`${api}/${model}`, {
        method: "PUT",
        headers:{
            'Content-type':"application/json"
        },
        body: JSON.stringify(item)
    })
    const json = await response.json()
    return json
}

export async function getItem(model, item) {
    const response = await fetch(`${api}/${model}/${item}`, {
        method: "GET",
        headers:{
            'Content-type':"application/json"
        }
    })
    const json = await response.json()
    return json
}

export async function getItems(model) {
    const response = await fetch(`${api}/${model}/many`, {
        method: "GET",
        headers:{
            'Content-type':"application/json"
        }
    })
    const json = await response.json()
    return json
}

export async function sendEmail(email, name, mobile, text) {
    const response = await fetch(`${api}/sendEmail/`, {
        method: "POST",
        headers:{
            'Content-type':"application/json"
        },
        body: JSON.stringify({
            email: email,
            name: name,
            mobile: mobile,
            text: text
        })
    })
    const json = await response.json()
    return json
}