import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import AppThemeProvider from './styles/AppThemeProvider';
import GlobalStyle from './styles/GlobalStyles';
import { determineIsMobile } from './website/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isMobile = determineIsMobile()

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <GlobalStyle isMobile={isMobile}/>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </AppThemeProvider>
  </React.StrictMode>
);
