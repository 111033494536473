import React from "react"
import { ThemeProvider } from "styled-components"


export default ({ children }) => {

  const colors = {
    turq: '#4fb1a1',
    orange: '#f5a636',
    input: '#afddd8'
  }

  const theme = {
    colors: colors
  }

  return(
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}