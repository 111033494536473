import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ClientContainer from './client/ClientContainer';
import AppThemeProvider from './styles/AppThemeProvider';
import GlobalStyle from './styles/GlobalStyles';
import { determineIsMobile } from './utils/utils';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isMobile = determineIsMobile()

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <GlobalStyle isMobile={isMobile}/>
      <ClientContainer.Provider>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </ClientContainer.Provider>
    </AppThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
