import { useEffect, useState } from "react"
import GetValue from "./GetValue"
import { Controller } from "react-hook-form"
import InputWrapper from "./InputWrapper"
import styled from "styled-components"

const Input = styled.input`
width: 100%;
height: 30px;
border-radius: 10px;
border: 1px solid #e1e1e1;
background-color: #fcfcfc;
padding: 5px 10px;
`

const StyledTextArea = styled.textarea`
  height: 150px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  background-color: #fcfcfc;
  padding: 5px 10px;
`


export default ({ 
    form, 
    name,
    defaultValue,
    label,
    style,
		labelStyle,
		placeholder,
		disabled,
    verticle,
    className,
    textArea
    }) => {       
		
		const [value, setValue] = useState('')
    const formValue = form.watch(`${name}`)

    useEffect(() => {
      setValue(formValue)
    }, [formValue])
			
    const onChange = (e) => {
        form.setValue(name, e.target.value)
				setValue(e.target.value)
    }

    useEffect(() => {
        if (!form) return
        form.register(name)
        form.setValue(name, GetValue(form, name) || defaultValue)
				setValue(GetValue(form, name))
    }, [])

    const getTextInput = () => {
      return (
        <div className={className} style={style}>
          <InputWrapper verticle={verticle} style={labelStyle} label={label} type='text'>
            {textArea ? 
              <StyledTextArea 
                value={value}
                id={name}
                onChange={onChange}
              />
              :
              <Input 
                value={value}
                id={name}
                type="text"
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
              />
            }
          </InputWrapper>
        </div>
      )
    }

    return (
      <Controller 
        control={form.control}
        name={name}
        defaultValue={defaultValue}
        render={getTextInput}
      />
    )
}