import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import ServiceView from "./website/service/ServiceView";
import AboutView from "./website/about/AboutView";
import ContactView from "./website/contact/ContactView";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeView from './website/home/HomeView';



function App() {
    return (
        <>
            <ToastContainer className='custom-toast'/>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/service" element={<ServiceView />} />
                <Route path="/about" element={<AboutView />} />
                <Route path="/contact" element={<ContactView />} />
            </Routes>
        </>
    );
}

export default App;
