import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { determineIsMobile } from "../utils"

const Wrapper = styled.div`
  background-color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: -10;
`
const InnerWrapper = styled.div`
  padding: ${props => props.isMobile ? '60px 20px' : '60px 0px'};
  background-color: #222222;
  display: flex;
  align-items: ${props => props.isMobile ? 'center' : 'flex-start'};
  justify-content: space-between;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  width: 100%;
  max-width: 1400px;
  gap: 40px;

  h5 {
    font-size: 14px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.3);
    font-family: Josefin Sans;
    margin-bottom: 10px;
  }

  p {
    color: white;
    font-family: Josefin Sans;
    font-size: 13px;

  }
`

const LowerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #333333;
  justify-content: center;
  border-top-style: solid;
  border-width: 1px;
  border-color: #444444;
`

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.isMobile ? 'center' : 'flex-start'};
  text-align: ${props => props.isMobile ? 'center' : 'left'};
  width: ${props=> props.isMobile ? '100%' : '250px'};
  line-height: 22px;
`

const StyledNavLink = styled(NavLink)`
  color: white;
  cursor: pointer;
  font-family: Josefin Sans;
  font-size: 13px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #A9A9A9;
  }
`

export default () => {

  const isMobile = determineIsMobile()

  return (
    <Wrapper>
    <InnerWrapper isMobile={isMobile}>
      <InfoBlock isMobile={isMobile}>
        <h5>ZINGARA MASSAGE</h5>
        <p>Zingara Massage will help you achieve fitness maintenance and improved well being.</p>
        <p>Located in Waitara, NSW Australia. Servicing the upper North Shore.</p>
      </InfoBlock>
      <InfoBlock isMobile={isMobile}>
        <h5>CONTACT</h5>
        <p style={{ fontWeight: 800, marginBottom: 12 }}>Millennium Health Club</p>
        <p style={{ marginBottom: 12 }}>9 Alexandria Parade, Waitara NSW 2077</p>
        <p>0415 438 210</p>
        <p>amy@zingara.com.au</p>
      </InfoBlock>
      <InfoBlock isMobile={isMobile}>
        <h5>SITEMAP</h5>
        <StyledNavLink to='/about'>About</StyledNavLink>
        <StyledNavLink to='/contact'>Contact</StyledNavLink>
        <StyledNavLink to='/service'>Service</StyledNavLink>
        <StyledNavLink to='/'>Zingara Home</StyledNavLink>
      </InfoBlock>
    </InnerWrapper>
    <LowerWrapper>
      <div style={{ maxWidth: 1400, width: '100%', paddingTop: 15, paddingBottom: 15, textAlign: isMobile ? 'center' : 'left' }}>
        <p style={{ color: '#ffffff', fontFamily: 'Josefin Sans', fontSize: 13 }}>© Copyright - Zingara Massage, 2020</p>
      </div>
    </LowerWrapper>
    </Wrapper>
  )
}