import React from "react";
import styled from "styled-components";

const ButtonWrapper = styled.button`
  padding: 10px 50px;
  border-radius: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.primary ? props.theme.colors.turq : props.warning ? 'red' : 'white'};
  background-color: ${props => props.theme.colors.turq};
  width: fit-content;
  color: ${props => props.primary || props.warning ? 'white' : 'black'};

  
`

export default ({
  children,
  primary,
  warning,
  onClick,
  style
}) => {

  return (
    <ButtonWrapper style={style} primary={primary} warning={warning} onClick={onClick}>
      {children}
    </ButtonWrapper>
  )
}