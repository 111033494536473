import React from "react"
import styled, { useTheme } from "styled-components"
import zingaraservices from '../../assets/zingaraservices.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserGroup, faMapMarkerAlt, faDatabase, faIdCard } from "@fortawesome/free-solid-svg-icons"
import CoreView from "../CoreView"
import { determineIsMobile } from "../utils"

const TitleTextWrapper = styled.div`
margin: 0 20vw;
display: flex;
flex-direction: column;
padding: 40px 0;
align-items: center;
text-align: center;

h5 {
  font-size: 15px;
  font-family: Josefin Sans;
  color: #9d9d9d;
  font-weight: 500;
  margin: 13px 0;
}
h3 {
  font-size: 28px;
  font-family: Josefin Sans;
  font-weight: 500;
}
`

const MainBodyWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
`

const InformationWrapper = styled.div`
display: flex;
flex-direction: row;
padding: 10px;
`

const InformationIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
border-radius: 50%;
width: 74px;
height: 74px;
border-style: solid;
border-width: 1px;
border-color: ${(props) => props.theme.colors.orange}
`

const InformationText = styled.div`
display: flex; 
flex-direction: column;
flex-shrink: 100;
padding: 0 20px;

h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

ol, ul {
  padding: 0 20px;
}

li, p {
  margin-bottom: 18px;
  color: #9d9d9d;
  font-family: Josefin Sans;
  font-size: 14px;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: ${props => props.theme.colors.turq};
  margin-bottom: 18px;
  font-family: Josefin Sans;
  font-size: 14px;
  font-weight: 800;
}

`


export default () => {

  const theme = useTheme()

  const isMobile = determineIsMobile()

  return(
    <CoreView
      title='SERVICES'
      subTitle={`"Zingara Massage is \n\n A reflection of my commitment \n\n to positive mind and body outcomes \n\n For myself and for you"`}
    >
      <TitleTextWrapper>
        <h5 style={{marginBottom: 8, marginTop: 0}}>Zingara Massages</h5>
        <h3>HELP IMPROVE</h3>
        <h5>Posture</h5>
        <h5>Pain relief</h5>
        <h5>Injury prevention</h5>
        <h5>The promotion and maintenance of healthy muscles</h5>
      </TitleTextWrapper>
      <MainBodyWrapper isMobile={isMobile}>
        <div style={{ width: isMobile ? '100%' : '50%' }}>
          <img style={{ width: '100%'}} src={zingaraservices}/>
        </div>
        <div style={{ width: isMobile ? '100%' : '50%', display: 'flex', flexDirection: 'column' }}>
          <InformationWrapper>
            <InformationIcon>
              <FontAwesomeIcon size="lg" color={theme.colors.orange} icon={faUserGroup}/>
            </InformationIcon>
            <InformationText>
              <h2>A TYPICAL SESSION</h2>
              <ol>
                <li>Full body, or specific muscle group, postural assessment. (If necessary this will incorporate: Range of motion testing, Strength test or Specific testing for syndromes, disorders, skeletal, muscular or structural diseases etc.)</li>
                <li>Remedial massage treatment</li>
                <li>Follow up postural assessment</li>
                <li>Other advice ie; stretches, strengthen exercises and more</li>
                <li>Ongoing treatment plan</li>
              </ol>
            </InformationText>
          </InformationWrapper>
          <InformationWrapper>
            <InformationIcon>
              <FontAwesomeIcon size="lg" color={theme.colors.orange} icon={faMapMarkerAlt}/>
            </InformationIcon>
            <InformationText>
              <h2>LOCATION</h2>
              <p style={{ color: theme.colors.turq, fontWeight: 800 }}>Available: Monday – Friday</p>
              <p>7:30am – 6:00pm</p>
              <p>9 Alexandria Parade,</p>
              <p>Waitara NSW 2077</p>
              <a href="https://www.millenniumhealthclub.com.au" target="_blank" rel="noopener noreferrer">www.millenniumhealthclub.com.au</a>
            </InformationText>
          </InformationWrapper>
          <InformationWrapper>
            <InformationIcon>
              <FontAwesomeIcon size="lg" color={theme.colors.orange} icon={faDatabase}/>
            </InformationIcon>
            <InformationText>
              <h2>PRICING</h2>
              <p>Clinic Consultation Fee:</p>
              <p>45 minutes - $105</p>
              <p>60 minutes - $145</p>
              <p>Mobile Consultation Fee:</p>
              <p>60 minutes – $145 + $50 call out fee</p>
              <p style={{ color: theme.colors.turq, fontWeight: 800 }}>All health funds accepted</p>
            </InformationText>
          </InformationWrapper>
          <InformationWrapper>
            <InformationIcon>
              <FontAwesomeIcon size="lg" color={theme.colors.orange} icon={faIdCard}/>
            </InformationIcon>
            <InformationText>
              <h2>ABOUT AMY</h2>
              <p>Amy is a Member of The Australian Traditional Medicine Society and the founder of Zingara Massage established 2015.  Amy’s qualifications include the following:</p>
              <ul>
                <li>Certificate IV Relaxation Massage – The Australasian College of Natural Therapies</li>
                <li>Diploma of Remedial Massage – The Australasian College of Natural Therapies</li>
                <li>Diploma of Sports massage – ITEC</li>
                <li>Dry Needling Level 1 & 2 – College of Eastern Medicine</li>
              </ul>
              <a href='/about'>READ MORE HERE ...</a>
            </InformationText>
          </InformationWrapper>
        </div>
      </MainBodyWrapper>
    </CoreView>
  )
}