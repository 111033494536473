import React from "react"
import Header from "../header/HeaderView"
import FooterView from "../footer/FooterView"
import styled from "styled-components"
import amyAbout from '../../assets/amyAbout.png'
import { Twirl as Hamburger } from 'hamburger-react'
import CoreView from "../CoreView"
import { determineIsMobile } from "../../utils/utils"

const BodyWrapper = styled.div`
display: flex;
flex-direction: column;
margin: ${props => props.isMobile ? '0px' : '0px 30vw;'};
padding: 70px 0px;
`

const ParagraphWrapper = styled.div`
  p {
  font-family: Josefin Sans;
  color: #9D9D9D;
  font-size: 14px;
  margin-bottom: 13px;
}
`

const InnerBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.isMobile ? '0px 10px' : '0px'};

  h3 {
    margin-top: 50px;
    font-size: 20px;
    font-family: Josefin Sans;
    font-weight: 400;
  }

  p {
    font-family: Josefin Sans;
    color: #9D9D9D;
    font-size: 16px;
  }
`

export default () => {

  const isMobile = determineIsMobile()

  return(
    <CoreView
      title="HI I'M AMY"
      subTitle="It's nice to meet you..."
    > 
      <BodyWrapper isMobile={isMobile}>
        <img src={amyAbout}/>
        <InnerBodyWrapper isMobile={isMobile}>
          <h3 style={{ marginBottom: 10 }}>ABOUT AMY</h3>
          <p style={{ marginBottom: 16 }}>Amy is a Member of The Association of Massage Therapists and the founder of Zingara Massage established in 2015.</p>
          <p style={{ marginBottom: 16 }}>Zingara Massage is known for friendly, quality service and strives to bring quick and effective relief while guiding you to the knowledge to help self manage your health and fitness goals.</p>
          <p style={{ marginBottom: 16 }}>Amy’s qualifications include the following:</p>
          <p>Diploma of Remedial Massage – The Australasian College of Natural Therapies</p>
          <p>Diploma of Sports massage – ITEC</p>
          <p>Dry Needling Level 1 & 2 – College of Eastern Medicine</p>
          <p>Certificate 4 – Fitness (Personal Trainer) – Australian Institute of Fitness</p>
          <p>Certificate IV Relaxation Massage – The Australasian College of Natural Therapies</p>
          <p style={{ marginBottom: 30 }}>Certificate 4 Allied Health Assistance – Onfit College</p>
          <ParagraphWrapper>
            <p>Since 2015, Amy has worked on private clients of the local community in Sydney’s Upper North Shore. Zingara Massage provides mobile massage, event massage catering for sporting tournaments, corporate welbeing and business incentive or celebratory events.</p>
            <p>Amy also contracts part of her time to elite athletes from sports such as Australian Football League; GWS Giants , NRL; Parramatta Eels and the international Rugby Union team; Wallabies.</p>
            <p>“I believe education and self improvement is a constant in my life, whether it be learning a new skill or improving existing proficiencies. I am always striving to experience and embrace new challenges. My balanced fitness regime includes rock climbing, archery, lyra and trail running.”</p>
          </ParagraphWrapper>
        </InnerBodyWrapper>
      </BodyWrapper>
    </CoreView>
  )
}