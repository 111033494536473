import { NavLink } from "react-router-dom"
import styled from "styled-components"

const Wrapper = styled.div`
  height: 90vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: transform 1s ease-in-out;
`

const StyledLink = styled(NavLink)`
  font-size: 36px;
  color: white;
  text-decoration: none;
  font-family: Josefin Sans;
`
export default ({
  burgerOpen
}) => {

  return (
    <Wrapper open={burgerOpen}>
      <StyledLink to='/'>Home</StyledLink>
      <StyledLink to='/service'>Service</StyledLink>
      <StyledLink to='/about'>About</StyledLink>
      <StyledLink to='/contact'>Contact</StyledLink>
    </Wrapper>
  )
}