import React, { useState } from "react";
import { getItem } from "../services/Rest";
import { createContainer } from "unstated-next";

function useClient() {

    const [client, setClient] = useState()

    const handleSetClient = (data) => {
        setClient(data)
    }

    return {
        client, setClient
    }
}

const ClientContainer = createContainer(useClient)
export default ClientContainer

export function withClient(Component) {
    return function WrappedComponent(props) {
        const c = ClientContainer.useContainer()
        return <Component {...props} />
    }
}