import React, { useRef, useState } from "react"
import styled, { useTheme } from "styled-components"
import aboutzingara from '../../assets/aboutzingara.jpg'
import Input from "../../components/Input"
import { useForm } from "react-hook-form"
import Button from "../../components/Button"
import { Toast } from "../../components/Toast"
import constants from '../../assets/constants.json'
import { ClipLoader } from 'react-spinners'
import CoreView from "../CoreView"
import { determineIsMobile } from "../utils"
import emailjs from '@emailjs/browser'

const TextWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 50px 10px 20px 10px;
text-align: center;

h3 {
  font-family: Josefin Sans;
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 500;
}

p {
  font-family: Josefin Sans;
  font-size: 15px;
  color: #9D9D9D;
  margin: 11px 0;
}

`

const FormWrapper = styled.div`
width: ${props => props.isMobile ? '90%' : '60%'};
margin: auto;
display: flex;
flex-direction: column;
margin-bottom: 20px;

input {
  height: 40px;
  margin-bottom: 20px;
}

.message {
  input {
    height: 150px;
    text-align: left;
  }
}
`

const ImageWrapper = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 50px 0;
border-top-style: solid;
border-width: 1px;
border-color: #e1e1e1;
`

export default () => {

  const theme = useTheme()
  const form = useForm({})
  const [pending, setPending] = useState(false)
  const isMobile = determineIsMobile()
  const formRef = useRef()

  const { EMAIL_SUCCESS, EMAIL_FAIL } = constants

  const resetFormValues = () => {
    form.setValue('from_name', '')
    form.setValue('from_mobile', '')
    form.setValue('from_email', '')
    form.setValue('enq_message', '')
  }

  const onSubmit = (e) => {
    setPending(true)
    e.preventDefault()
    const form = formRef.current
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form, {
      publicKey: 's_P8-OemILIaXCT3a',
    })
    .then((res) => {
      resetFormValues()
      Toast(EMAIL_SUCCESS, 5000, 'success')
      setPending(false)
    }).catch((e) => {
      resetFormValues()
      Toast(EMAIL_FAIL, 5000, 'dark')
      setPending(false)
    })
  }

  return(
    <CoreView
      title='GET IN TOUCH'
      subTitle={`Want to work with me? Or just say "Hello?" \n Use the form below`}
    >
      <TextWrapper>
        <h3>Contact Details</h3>
        <p>I am ready whenever you are</p>
        <p>Available by Appointment: Monday to Friday – 7:30am till 6pm</p>
        <p style={{ fontWeight: 800, color: theme.colors.turq }}>MILLENNIUM HEALTH CLUB</p>
        <p>9 Alexandria Parade,</p>
        <p>Waitara NSW 2077</p>
        <p>Phone: 02 9987 4277</p>
        <p>www.millenniumhealthclub.com.au</p>
      </TextWrapper>
      <FormWrapper isMobile={isMobile}>
        <form ref={formRef} onSubmit={onSubmit}>
          <h3>Bookings & Enquiry</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
            <Input verticle style={{ width: isMobile ? '100%' : '49%' }} label="Name" name='from_name' form={form}/>
            <Input verticle style={{ width: isMobile ? '100%' : '49%' }} label="Email" name='from_email' form={form}/>
          </div>
          <Input verticle label="Mobile" name='from_mobile' form={form}/>
          <Input textArea className='message' verticle label="Message" name='enq_message' form={form}/>
          <div style={{ width: '100%', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <Button style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', width: 150, height: 46 }} onClick={onSubmit} primary>
              {pending ? 
              <ClipLoader color="white" size='20px'/> 
              : 
              'Submit'
              }
            </Button>
          </div>
        </form>
      </FormWrapper>
      <ImageWrapper>
        <img style={{ width: isMobile ? '100%' : 1200 }} src={aboutzingara}/>
      </ImageWrapper>
    </CoreView>
  )
}