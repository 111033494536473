import React, { useState } from "react"
import Header from "../header/HeaderView"
import styled from "styled-components"
import zingaraLogoNoCircle from '../../assets/zingaraLogoNoCircle.jpg'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle, faMapMarkerAlt, faDatabase, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { Link, useNavigate } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import image1 from '../../assets/carouselmage1.jpg'
import image2 from '../../assets/carouselImage2.jpg'
import image3 from '../../assets/carouselImage3.jpg'
import FooterView from "../footer/FooterView"
import { determineIsMobile } from "../../utils/utils"
import CoreView from "../CoreView"

const isMobile = determineIsMobile()

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  padding: 70px 50px;
  margin: auto;
  align-items: center;
  z-index: -10;
`

const Logo = styled.img`
  width: 350px;
  height: 350px;
  z-index: -10;
`

const SquaresWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  gap: 70px;
  margin-top: 40px;
`

const SquareInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Square = styled.div`
  position: relative;
  padding: 45px 20px 20px 20px;
  height: ${isMobile ? 'fit-content' : '350px'};
  width: 315px;
  text-align: center;
  background-color: #fcfcfc;
  z-index: -10;
  h3 {
    font-family: Josefin Sans;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  p { 
    font-family: Josefin Sans;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: #9d9d9d;
  }
`

const Circle = styled.div`
  position: absolute;
  left: 130px;
  top: -22.5px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.turq};
`

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 150px;
  margin-top: 30px;
`

const InfoLine = styled.div`
  flex-grow: 1;
  height: 1px;
  background-color: #e1e1e1;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 305px;
  margin-top: 30px;
  margin-bottom: 30px;
`

const Button = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 110px;
  padding: 8px 10px;
  margin: 20px 0px;
  border: 0;
  background-color: ${props => props.theme.colors.turq};
  font-family: Josefin Sans;
  color: white;
  box-shadow: 2px 1px 1px black;
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
`

const ContactWrapper = styled.div`
  display: flex;
  padding: 50px 0;
  transition: background-color 0.3s;
  background-color: ${({isHovered, ...props}) => (isHovered ? '#444444' : props.theme.colors.turq)};
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: -10;

  h5 {
    color: white;
    font-size: 20px;
    font-family: Josefin Sans;
    transition: transform 0.3s ease;
    transform: ${({ isHovered }) => (isHovered ? 'translateX(-10px)' : 'translateX(0)')}; /* Adjust as needed */
  }

  p{
    font-size: 16px;
    font-family: Josefin Sans;
    color: rgba(255, 255, 255, 0.7);
  }
`

const IconWrapper = styled.div`
  opacity: ${({ isHovered }) => (isHovered ? 1 : 0)};
  transition: opacity 0.3s ease;
  margin-left: 10px;
  color: white;
  margin-bottom: 10px;
`

export default () => {

  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/contact')
  }


  return(
    <CoreView
      title="ZINGARA MASSAGE"
      subTitle="Zingara massage is a professional sports and remedial massage service. Zingara takes a holistic approach to maxismising your health and wellbeing. Together, we are committed to assisting you reach your fitness goals."
    >
      <Wrapper>
        <Logo src={zingaraLogoNoCircle}></Logo>
        <SquaresWrapper isMobile={isMobile}>
        <SquareInnerWrapper>
          <Square>
            <Circle />
            <h3>HOW IS ZINGARA DIFFERENT?</h3>
            <p>At Zingara we want to help you reach your full potential</p>
            <p>Your Zingara, therapists always take the time to build a customised treatment plan for you</p>
            <p>You will receive a range of treatments, specifically tailored to your clinical presentation</p>
          </Square>
          <Button to='/about'><FontAwesomeIcon style={{ color: 'white' }} icon={faInfoCircle} />ABOUT</Button>
        </SquareInnerWrapper>
        <SquareInnerWrapper>
          <Square>
            <Circle />
            <h3>WHO CAN BENEFIT?</h3>
            <p>Provides quality remedial massage to people from diverse walks of life – for instance sports people, musicians, office workers, home-makers and others</p>
            <p>Useful for anyone who wants to improve his or her fitness</p>
          </Square>
          <Button to='/contact'><FontAwesomeIcon style={{ color: 'white' }} icon={faMapMarkerAlt} />CONTACT</Button>
        </SquareInnerWrapper>
        <SquareInnerWrapper>
          <Square>
            <Circle />
            <h3>OUR SERVICE</h3>
            <p>Are you are an active sportsperson? Would you like to improve your posture? Or would you like to be more physically active?</p>
            <p>Zingara Massage will help you achieve fitness maintenance and improved well being.</p>
          </Square> 
          <Button to='/service'><FontAwesomeIcon style={{ color: 'white' }} icon={faDatabase} />SERVICES</Button>
        </SquareInnerWrapper>
      </SquaresWrapper>
      <InfoWrapper>
        <InfoLine />
          <div style={{ marginRight: 10, marginLeft: 10 }}><FontAwesomeIcon style={{ color: '#9d9d9d' }} icon={faInfoCircle} /></div>
        <InfoLine />
      </InfoWrapper>
      </Wrapper>
      <Carousel autoPlay interval={5000} infiniteLoop showStatus={false} showThumbs={false}>
        <div>
          <img style={{ height: isMobile ? 200 : 700, objectFit: 'cover', zIndex: -10 }} src={image1}/>
        </div>
        <div>
          <img style={{ height: isMobile ? 200 : 700, objectFit: 'cover' }} src={image2}/>
        </div>
        <div>
          <img style={{ height: isMobile ? 200 : 700, objectFit: 'cover' }} src={image3}/>
        </div>
      </Carousel>
      <ContactWrapper onClick={onClick} isHovered={isHovered} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h5>Get in Touch</h5>
          <IconWrapper isHovered={isHovered}>
            <FontAwesomeIcon icon={faEnvelope} />
          </IconWrapper>
        </div>
        <p>Let’s have a chat – send me an enquiry today!</p>
      </ContactWrapper>
    </CoreView>
  )
}