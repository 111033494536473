import { toast } from 'react-toastify'

export const Toast = (label, delay, variant) => {
  toast(label, {
    position: "top-center",
    autoClose: delay ? delay : false,
    closeButton: true,
    hideProgressBar: true,
    pauseOnHover: true,
    draggable: false,
    className: `custom-toast ${variant}`
  });
}