import styled from "styled-components"
import logo from '../../assets/logo.png'
import { Twirl as Hamburger } from 'hamburger-react'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  padding: 10px 30px;
  height: 15vh;
`

const Logo = styled.img`
  height: 95%;
`

export default ({
  backgroundColor,
  toggled,
  toggle
}) => {

  return(
    <Wrapper backgroundColor={backgroundColor}>
      <Logo src={logo}/>
      <Hamburger toggled={toggled} toggle={toggle} size='88' color="#fff"/>
    </Wrapper>
  )
}