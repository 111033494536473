import React from 'react'
import styled, {css} from 'styled-components'


const Wrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.verticle ? 'column' : 'row'};
    text-align: left;
    align-items: ${props => props.type === 'checkbox' ? 'flex-start' : props.verticle ? 'flex-start' : 'center'};
`

const Label = styled.p`
    margin-left: ${props => props.type === 'checkbox' ? '10px' : 0};
    margin-right: ${props => props.type === 'checkbox' ? 0 : '10px'};
    color: ${props => props.theme.colors.turq};
    font-weight: 800;
    align-items:center;
    justify-content: center;
`

export default ({
    children,
    label,
    error,
    type,
    style,
    verticle
}) => {

    const noLabel = !label || label.trim(' ') === ''

    return(
        <Wrapper verticle={verticle} type={type}>  
            { type === 'checkbox' ? 
            <>
                {children}
                {noLabel ? null : <Label type={type} style={style}>{label}</Label>}
            </> 
            : 
            <>
                {noLabel ? null : <Label type={type} style={style}>{label}</Label>}
                {children}
            </>}
        </Wrapper>
    )
}