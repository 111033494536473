import React, { useEffect } from "react"
import styled from "styled-components"
import logo from '../../assets/logo.png'
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"

const Wrapper = styled.div`
  background-color: ${props => props.backgroundColor};
  height: 90px;
`

const InnerWrapper = styled.div`
  width: 50vw;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.img`
  height: 88px;
`

const Nav = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;

  li {
    float: left;
  }
`

const StyledNavLink = styled(NavLink)`
  padding: 10px 0;
  color: white;
  margin: 0px 10px;
  cursor: pointer;
  font-family: Josefin Sans;
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s, color 0.2s;
  &.active {
    border-color: white;
  }
  &:hover {
    border-color: #F5F5F5;
    color: #F5F5F5;
  }
`

const FacebookIcon = styled(NavLink)`
  display: inline-block;
  margin-left: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  color: white;
  transition: background-color 0.2s;
  &:hover {
    background-color: #115293;
  }
`

const NavHeader = ({ backgroundColor }) => {

  return(
    <Wrapper backgroundColor={backgroundColor}>
      <InnerWrapper>
        <Logo src={logo}/>
        <Nav>
            <li>
              <StyledNavLink to="/" activeClassName='active'>Home</StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/service">Service</StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/about">About</StyledNavLink>
            </li>
            <li>
              <StyledNavLink to="/contact">Contact</StyledNavLink>
            </li>
            <li>
              <FacebookIcon target="_blank" rel="noopener noreferrer" to='https://www.facebook.com/zingaramassage'>
                <FontAwesomeIcon icon={faFacebookF}/>
              </FacebookIcon>
            </li>
        </Nav>
      </InnerWrapper>

    </Wrapper>
  )
}
export default NavHeader