import { createGlobalStyle } from "styled-components";


const GlobalStyle = createGlobalStyle`
    p {
      margin-bottom: 0;
    }

    .custom-modal {
      .modal-body {
        display: flex;
      }
    }

    .medicalFormInput {
      input {
        width: 100%;
        height: 30px;
        border-radius: 20px;
        border: 0;
        background-color: ${props => props.theme.colors.input};
        padding: 5px 10px;
      }
    }

    textarea {
      width: 100%;
      resize: none;
    }

  .custom-toast{
    color: white !important;
    min-height: 48px !important;
    width: ${props => props.isMobile ? '90vw !important' : '600px !important'};
    padding: 0 0 0 16px !important;
    font-family: ${(props) => props.theme.font};
    &.success {
      background: #008542 !important;
    }
    &.dark {
      background: #383A40 !important;
    }
    $.danger {
      background: #8B0000 !important;
    }
    .Toastify__toast-container {
      width: fit-content !important;
    }
    .Toastify__toast-body {
      padding: 0;
      margin: 0;
    }
    .Toastify__close-button {
      align-self: center;
      width: 48px;
      height: 48px;
      svg {
        height: 24px;
        width: 24px;
        color: white;
      }
    }
  }
`

export default GlobalStyle