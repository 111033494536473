import { useEffect, useState } from "react"
import FooterView from "./footer/FooterView"
import Header from "./header/HeaderView"
import styled from "styled-components"
import BurgerMenu from "./BurgerMenu"
import { useLocation } from "react-router-dom"

const Wrapper = styled.div`
  background-color: ${({ burgerOpen }) => (burgerOpen ? "#333" : "transparent")};
  transition: background-color 1s ease;
`


export default ({
  children,
  title,
  subTitle
}) => {

  const [burgerOpen, setBurgerOpen] = useState(false)

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Wrapper burgerOpen={burgerOpen}>
      <Header burgerOpen={burgerOpen} setBurgerOpen={setBurgerOpen} title={title} subTitle={subTitle}/>
      {burgerOpen ? 
        <BurgerMenu burgerOpen={burgerOpen}/>
        :
        <>
          {children}
          <FooterView />
        </>
      }
    </Wrapper>
  )
}