import React, { useEffect, useState } from "react"
import NavHeader from "./NavHeaderView"
import TitleHeader from "./TitleHeader"
import { useTheme } from "styled-components"
import { determineIsMobile } from "../utils"
import BurgerHeader from "./BurgerHeader"


const Header = ({ title, subTitle, burgerOpen, setBurgerOpen }) => {

  const isMobile = determineIsMobile()

  const theme = useTheme()

  const getBackgroundColor = () => {
    if (window.location.pathname === '/service') {
      return theme.colors.orange
    } else {
      return theme.colors.turq
    }
  }

  const backgroundColor = getBackgroundColor()


  return (
    <div>
      {isMobile ? <BurgerHeader toggled={burgerOpen} toggle={setBurgerOpen} backgroundColor={backgroundColor}/> : <NavHeader backgroundColor={backgroundColor} />}
      {burgerOpen ? null : <TitleHeader backgroundColor={backgroundColor} title={title} subTitle={subTitle} />}
    </div>
  )
}

export default Header